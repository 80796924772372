import React from "react";
import './CatalogueHeader.css';
import {ProxyLogo} from "../ProxyLogo";


export interface CatalogueHeaderProps {

}

export const CatalogueHeader: React.FC<CatalogueHeaderProps> = ({...props}) => {

  // @ts-ignore
  const env = JSON.parse(sessionStorage.getItem('env'));
  return (
    <div className={'catalogue-header-container'}>
      <div className={'catalogue-header-logos'}>
        <img className={'institution-logo-catalogue'} src={env["logo"]} alt={env["name"]} />
        <ProxyLogo size={'small'} />
      </div>
      <div className={'catalogue-header-title'}>
        <h1 className={'institution-name'}>{env["name"]}</h1>
        <h3 className={'catalogue-header-description'}>{env["slug"] === 'bibliomedic' ? 
            'Servicios de valor Agregado para Miembros de la comunidad' 
            : 'Bases de datos académicas para la comunidad universitaria'}
        </h3>
      </div>
    </div>
  );
};
