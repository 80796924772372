import React from 'react';
import {AuthForm} from "../components/AuthForm";

function Unauthorized() {
  return (
    <AuthForm errorMessage={'Credenciales inválidas para este ambiente'} />
  );
}

export default Unauthorized;
