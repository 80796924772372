import React from "react";
import './AuthForm.css';
import StudentsImg from './students.jpg';
import {CreateUserAuth} from "../CreateUserAuth";
import {ProxyLogo} from "../ProxyLogo";
import {GoogleAuthButton} from "../GoogleAuthButton";
import {MicrosoftAuthButton} from "../MicrosoftAuthButton";
import Loading from 'react-loading';
import {bkAccess} from "../../middleware/AccessBooklick";
export interface AuthFormProps {
  redirectUrl?: string,
  external?:boolean,
  loading?:boolean,
  errorMessage?: string,
}

export const AuthForm: React.FC<AuthFormProps> = ({
    redirectUrl,
    external,
    loading,
    errorMessage,
    ...props
  }) => {

  // @ts-ignore
  const env = JSON.parse(sessionStorage.getItem('env'));

  const authMethods: any[] = [];

  for (let method  of env['authMethod']) {
    if (method === 'email') {
      authMethods.push(<CreateUserAuth key="email-auth-method" redirectUrl={redirectUrl} />);
    }
    if (method === 'google') {
      authMethods.push(<GoogleAuthButton key="gmail-auth-method" redirectUrl={redirectUrl}/>);
    }
    if (method === 'microsoft') {
      authMethods.push(<MicrosoftAuthButton key="microsoft-auth-method" redirectUrl={redirectUrl}/>);
    }

  }

  const bodyContent = () => {
    if (loading) {
      return <div className={'input-group'}>
        <Loading type={'cylon'} color={'#F4436C'} height={100} width={80} />
      </div>
    }

    // @ts-ignore
    if (external && window.redirectURL && window.redirectURL.includes('booklick')) {
      return <div className={'input-group'}>
        <h1>Si no redirecciona automaticamente dale click en el siguiente botón. </h1>
        <button className='redirect-button' onClick={() => {
          const user = localStorage.getItem('redirectToBKUser')
          const bkUrl = localStorage.getItem('redirectToBKURL')
          if (user && bkUrl)
            bkAccess(user, bkUrl)
        }}>Redireccionar</button>
      </div>
    }

    if (external) {
      return <div className={'input-group'}>
        <h1>Por favor espera un momento, te estamos redireccionando al contenido que necesitas</h1>
        <Loading type={'cylon'} color={'#F4436C'} height={100} width={80} />
      </div>
    }

    return <div className={'input-group'}>
      <h1 className={'form-title'}>Sistema de autenticación</h1>
      {env.slug === 'ucp' && (
					<p className={'auth-method-message'}>Docentes y Administrativos</p>
			)}
      { errorMessage ? <h3 style={{color:'red'}}>{errorMessage}</h3>: '' }
      {authMethods}
    </div>
  }

  return (
    <div className={'auth-form-container'}>
      <div className={'auth-form-logos'}>
        <ProxyLogo size={'medium'}/>
        <img className={'institution-logo'} src={env["logo"]} alt={env["name"]} />
      </div>
      { bodyContent() }
      <div className={'decor-image'}
      style={{backgroundImage: `url(${env['banner'] || StudentsImg})`}}
      />
    </div>
  );
};
