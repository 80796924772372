import React, {useState} from "react";
import './AccessButton.css';
import {bkAccess} from "../../middleware/AccessBooklick";

import Loading from 'react-loading';
import {getInstitution} from "../../middleware/Instituion";
import {deleteCookies} from "../../middleware/CookieManager";


export interface AccessButtonProps {
  url: string
  isBooklick: boolean,
  isElibrolite:boolean,
  user: string |null
}

export const AccessButton: React.FC<AccessButtonProps> = ({url, isBooklick,isElibrolite, user,...props }) => {
  const [loading, setloading] = useState(false);
  if (isBooklick) {
    return (
      <button onClick={() => onAccessClick(authenticateBooklick, url, user)} className={'access-button'} >
        Acceder
      </button>
    )
  }else if(isElibrolite){
    if (loading) {
      return <Loading type={'cylon'} color={'#F4436C'} height={100} width={80} />
    }else{
      return (
        <button onClick={() => {
          setloading(!loading)
          authenticateElibro(user,setloading)

        }}
        className={'access-button'}>
          Acceder
        </button>
      )
    }

  }
  else {
    return (
      <a
        onClick={() => onAccessClick(null, null, null)}
        href={url}
        // eslint-disable-next-line
        target={"_blank"}
        className={'access-button'}
        rel={url.includes('leyex') ? "": "noopener noreferrer"}
      >
        Acceder
      </a>
    );
  }
};

const onAccessClick = (action: any|null, url: string|null, user: string|null) => {
    deleteCookies();
    if (action) {
      action(url, user);
    }
}

const authenticateBooklick = (url: string, user: string|null) => {
  if (user) {
    bkAccess(user, url)
  }
}

const authenticateElibro = (user: string|null,setloading: any) => {
  if(user){
    //@ts-ignore
    getInstitution(window.subdomin)
    .then(env => {
        //elibroAccess("jonattan@booklick.co")
        let Header = new Headers();
        //@ts-ignore
        Header.append("Authorization", `Token ${env.token}`);
        Header.append("Content-Type", "application/json");
        //@ts-ignore
        let body = JSON.stringify({secret:env.secret,channel_id:env.channel,user});
        let requestOptions = {
        method: 'POST',
        headers: Header,
        body: body,
        redirect: 'follow'
        };

        //@ts-ignore
        fetch("https://auth.elibro.net/auth/sso/", requestOptions)
          .then((response:any) => response.text())
          .then((result:any) => {
            setloading(false)
            window.open(JSON.parse(result).url)
          })
          .catch((error:any) => console.log('error', error))
        })

  }
}
