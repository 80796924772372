import React, {useState} from 'react';
import { useNavigate } from "@reach/router"
import { useTranslation } from 'react-i18next';
import Login from "./Login";
import firebase from "firebase";


function Home() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  // VALIDATION
  const [user, setUser] = useState(undefined);
  firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (firebaseUser) {
      // @ts-ignore
      setUser(firebaseUser);
    } else {
      setUser(undefined);
    }
  })

  if (user) {
    firebase.auth().signOut().then(() => {
      localStorage.clear();
      navigate(t('login-route'), {replace: true});
    });
  }

  return <Login />
}

export default Home;
