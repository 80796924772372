import React from 'react';
import { useNavigate } from "@reach/router"
import { useTranslation } from 'react-i18next';
import Login from "./Login";


function Home() {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Logic
  navigate(t('login-route'), {replace: true})
  return <Login />
}

export default Home;
