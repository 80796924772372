import React, {useState} from 'react';
import {AuthForm} from "../components/AuthForm";
import firebase from "firebase";

function Login() {

  // @ts-ignore
  const url = window.redirectURL;  
  const [user, setUser]:any = useState('');

  // Validation
  firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (firebaseUser) {
      setUser(firebaseUser);
    }     
  })

  

  if (user === '') {
    return (
      <AuthForm redirectUrl={url} loading={false}/>
    );
  }else if(user.email) {
    return (
      <AuthForm redirectUrl={url} external={true}/>
    );
  }else {
    return (
      <AuthForm redirectUrl={url} external={false}/>
    );
  } 

}

export default Login;