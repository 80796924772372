export const deleteCookies = () => {
  let cookies = document.cookie.split("; ");
  for (let c = 0; c < cookies.length; c++) {
    let cookie = cookies[c].split("=")[0]

    if(cookie !== "INSTITUTION" && cookie !== "AUTHCOOKIE"){
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        console.log('Domain', d.join('.'));
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          console.log(cookieBase + p.join('/'));
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  }
}
