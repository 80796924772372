import React, {useState} from 'react';
import {creatUser, signInUser} from "../middleware/EmailAuth";
import Loading from "react-loading";
import {AuthForm} from "../components/AuthForm";


function AutomaticLogin() {

  const [setError] = useState('');

  const url = new URL(window.location.href);
  const dataBase64 = url.searchParams.get('data');
  if (dataBase64) {
    const data = JSON.parse(atob(dataBase64));
    localStorage.setItem('ies', data.client);
    creatUser(data.user, data.user, setError)
      .catch(() => {
        signInUser(data.user, data.user, setError);
      })
    return <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'}}>
      <Loading type={'cylon'} color={'#F4436C'} height={100} width={80} />
    </div>
    ;
  }
  else {
    return <AuthForm external={false}/>;
  }
}

export default AutomaticLogin;
