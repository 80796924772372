import React from "react";
import './FuncSection.css'
import {CardFunction} from "../CardFunction";
import firebase from "firebase";

export interface FuncSectionProps {
  title: string
  functionsList: any,
  user: firebase.User|null
}

export const FuncSection: React.FC<FuncSectionProps> = ({title, functionsList, user, ...props }) => {

  const functionsCards = functionsList.map((functionEle: any) => {
    return <CardFunction
      img={functionEle.img}
      title={functionEle.name}
      description={functionEle.description}
      url={functionEle.url}
      user={user}
    />;
  });

  return (
    <div className={'func-section-container'}>
      <div className={'section-title-container'}>
        <div className={'section-title-line'}></div>
        <h3 className={'section-title'}>{title}</h3>
      </div>
      <div className={'section-functions-container'}>{functionsCards}</div>
    </div>
  )
}
