import React from "react";
import './TutorialCard.css';

export interface TutorialCardProps {
  title: string,
  url: string
}

export const TutorialCard: React.FC<TutorialCardProps> =
  ({title, url, ...props }) => {
  return (
    <div className={'tutorial-card'}>
      <h4>{title}</h4>
        <iframe height={'70%'} width={'80%'} src={url} title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
    </div>
  );
};
