

export const parseRedirection = (url: string) => {
  

  //let decoURI = decodeURIComponent(url)
  // @ts-ignore
  const env = JSON.parse(sessionStorage.getItem('env'));
  if (url.includes(env['domain'])) {
    return url.includes('http') ? url : `http://${url}`
  } else if(!url.includes(env['domain']) && !env['elibroLite']){
    const isRedirectEbsco = url.includes('ebscohost') && url.includes('returnToUrl') ? true : false;
    const urlParts =  isRedirectEbsco ? url.split('/'): decodeURIComponent(url).split('/') ;
    const domain = `${urlParts[0]}//${urlParts[2].replace(/\./g,'-')}.${env['domain']}`
    let pathResource = ''
    
    for (let i = 3; i < urlParts.length; i++) {
      pathResource += "/" + urlParts[i];      
    }
    return isRedirectEbsco ? domain + pathResource : decodeURIComponent(domain + pathResource)

  } else { // https://booklicktest.proxybk.com/login?url=https://elibro.net/es/lc/booklicktest/titulos/122766
    const urlParts = url.split('/');    
    return urlParts.reduce((acc, currentValue) => {
      if (!acc.switch && currentValue.includes('.')) {
        const subdomain = currentValue.replace(/\./g, '-');
        const newDomain = env['elibroLite']?`${subdomain}.${env['domain']}/authElibro`:`${subdomain}.${env['domain']}`;
        return {
          url: `${acc.url}/${newDomain}`,
          switch: true,
        }
      } else {
        return {
          ...acc,
          url: `${acc.url}/${currentValue}`,
        }
      }
    }, {url: '', switch: false}).url.substring(1);
  } 
}
