import firebase from "firebase/app";
import 'firebase/auth';
import { getUser } from "./AccessBooklick";
const jwkToPem = require('jwk-to-pem')

// -------------------------------------  Attributes -------------------------------- //

const publicKeys: any = {};

// -------------------------------------  Initializer -------------------------------- //

// Google Keys
const getKeys = () => {
  return fetch("https://www.googleapis.com/oauth2/v3/certs", {
    "method": "GET",
  }).then((response) => response.json())
    .then((response) => response.keys);
}

const keysPromise = getKeys();

keysPromise.then((keys) => {
  publicKeys[keys[0].kid] = jwkToPem(keys[0]);
  publicKeys[keys[1].kid] = jwkToPem(keys[1]);
})

// -------------------------------------  Methods -------------------------------- //

export const googleLogin = (url: string | undefined, t: any, isMobile: boolean = false) => {
  // @ts-ignore
  const env = JSON.parse(sessionStorage.getItem('env'));
  // @ts-ignore
  let provider = new firebase.auth.GoogleAuthProvider();

  firebase.auth().signInWithPopup(provider).then(function (result: any) {
    const idToken = result.credential.idToken;
    const user = getUser(idToken);

    // @ts-ignore
    if (user.endsWith(env['authorizedDomain']) || user.endsWith('booklick.co')) {
      if (url) {
        window.location.href = url;
      }
    }
  }).catch(function (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = error.credential;
    console.error(errorCode, errorMessage, email, credential)
  });
}

export const googleLoginWithRedirect = (url: string | undefined, t: any, isMobile: boolean = false) => {
  // @ts-ignore
  const env = JSON.parse(sessionStorage.getItem('env'));  
  // @ts-ignore
  let provider = new firebase.auth.GoogleAuthProvider();

  firebase.auth().signInWithRedirect(provider);

  firebase.auth()
  .getRedirectResult()
  .then(function (result: any) {
    const idToken = result.credential.idToken;
    
    const user = getUser(idToken);

    // @ts-ignore
    if (user.endsWith(env['authorizedDomain']) || user.endsWith('booklick.co')) {
      if (url) {
        window.location.href = url;
      }
    }
  }).catch(function (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = error.credential;
    console.error(errorCode, errorMessage, email, credential)
  });
}
