import config from '../general.json';


export const getCampusRegistration = async (user: string,institution: string) => {
    try {
        let myHeaders = new Headers();
        myHeaders.append("env", institution);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders            
        };

        let res = await fetch(config.msProxy + "/campus/" + user, requestOptions)
        let response = await res.json()

        return response

    
    } catch (error) {
        console.log(error)
    }    
};

export const postCampusRegistration = async (user: string, institution: string,campus: string) => {
    try {
        let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "institution": institution,
            "campus": campus,
            "email": user
            });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

        let res = await fetch(config.msProxy + "/campus", requestOptions)
        let response = await res.json()

        return response
    
    } catch (error) {
        console.log(error)
    }      
};