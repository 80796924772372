import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { microsoftLogin } from '../../middleware/MicrosoftAuth';
import './MicrosoftAuthButton.css'
import {useTranslation} from "react-i18next";
export interface MicrosoftAuthButtonProps {
  redirectUrl?: string
}

export const MicrosoftAuthButton: React.FC<MicrosoftAuthButtonProps> = ({redirectUrl, ...props }) => {
  const { t } = useTranslation();
    return (
        <button className={'microsoft-auth-button'} onClick={() => microsoftLogin(redirectUrl, t)}>
          <FontAwesomeIcon className={'gab-icon'} icon={faMicrosoft} /> Inicio de sesión con Microsoft
        </button>

    );

};
