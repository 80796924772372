import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import firebase from "firebase";
import Cookies from "js-cookie";
import {parseRedirection} from "./middleware/ManageRedirections";
import {signInUser} from "./middleware/EmailAuth";
import {getInstitution} from "./middleware/Instituion";
import Resources from './resources.json';
import {bkAccess} from "./middleware/AccessBooklick";
import {deleteCookies} from "./middleware/CookieManager";

// GET SUbdomian
const subdomin = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : '0';

// @ts-ignore
window.subdomin = /^\d+$/.test(subdomin) ? 'ucp' : subdomin;

//const { t } = useTranslation();

// -------------------------------------  Auth Initializer -------------------------------- //

// @ts-ignore
getInstitution(window.subdomin)
  .then(env => {
    /**
     * Store Config
     */
     
     let config = {
      authMethod: env['authMethod'],
      logo: env['logo'],
      name: env['name'],
      authorizedDomain: env['authorizedDomain'],
      banner: env['banner'],
      elibroLite: env['elibroLite'],
      domain:env['domain'],
      slug:env['slug']
    }

    sessionStorage.setItem('env',JSON.stringify(config))

    // GET URL REDIRECT (OPTIONAL)
    const losslessURL = window.location.href;

    // @ts-ignore
    window.redirectURL = losslessURL.includes('login?url=') ?
      parseRedirection(losslessURL.split('login?url=')[1] as string) : null;

      // @ts-ignore
      console.log('redirectURL con login', window.redirectURL)

    // @ts-ignore
    if (window.redirectURL) deleteCookies();

    // @ts-ignore
    if (!window.firebaseApp)  {
      // @ts-ignore
      window.firebaseApp = firebase.initializeApp({
        apiKey: env['apiKey'],
        authDomain: env['authDomain'],
        databaseURL: env['databaseURL'],
        projectId: env['projectId'],
        storageBucket: env['storageBucket']
      })
    }

    /** Login form LMS luisAmigo */
    //const encodedUserEmail = /\?m=(.*)/g.exec(window.location.search) || []
    //console.log('encodedUserEmail', encodedUserEmail)
    const searchParams = new URLSearchParams(window.location.search);
    // @ts-ignore
    const userEmail = searchParams.get('m') ? atob(searchParams.get('m'))  : undefined;
    // Decode the String
    //const userEmail = !encodedUserEmail[1] ? undefined : atob(encodedUserEmail[1]);
    if (userEmail) {
      localStorage.setItem('lmsUser' , userEmail);
      signInUser(env['lmsUser'] , env['lmsPassword'] , '');
      sessionStorage.setItem('login','logged in')

      //Redirección con autenticación. 
      // @ts-ignore
      if (searchParams.get('url')){
        searchParams.delete('m');
        const stringQuery = searchParams.toString();
        let newUrl = stringQuery.replace('url=','');

        try{
          newUrl = decodeURIComponent(newUrl);
        }catch (e) {
          //@ts-ignore
          newUrl = null;
          console.log(newUrl, ' error decodeURIComponent', e)
        }

        // @ts-ignore
        window.redirectURL = newUrl ? parseRedirection(newUrl as string) : null;
      }
      // @ts-ignore
      console.log('redirectURL', window.redirectURL)
    }
    
    // ---------------------------------  Methods Real Time --------------------------- //

    firebase.auth().getRedirectResult()
    .then(function(result) {
      console.log('redirect result user', result);
    })
    .catch((error=>console.log('error to redirect', error.message)))
    .finally(()=>console.log('finally event redirect'))
    
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        // @ts-ignore
        //const institution: string = window.subdomin;
        // @ts-ignore
        //const env = bkEnv[institution];
        
        if (firebaseUser && firebaseUser.email) {
            if (firebaseUser.email.endsWith(env['authorizedDomain']) || firebaseUser.email.endsWith('booklick.co') || 
            (env['slug'] === 'ucp' && firebaseUser.providerData[0]?.providerId === 'password')){
                // JWT Construction
              const jwt = require('jsonwebtoken');
              const token = jwt.sign({
                at_hash: `${firebaseUser.uid}${(new Date()).getUTCMilliseconds()}`,
                email: localStorage.lmsUser ? localStorage.lmsUser : firebaseUser.email,
                signature: 'proxybk'
              }, env['bkApiKey']);
              // Cookie setting
              // @ts-ignore
              Cookies.set('AUTHCOOKIE', token, { domain: `.${env['domain']}`, expires: 1 });
              // @ts-ignore
              Cookies.set('INSTITUTION', env['slug'], { domain: `.${env['domain']}`, expires: 1 });

              // Verification email send
              if (env['authMethod'] && env['authMethod'][0] === 'email' && !firebaseUser.emailVerified) {
                firebaseUser.sendEmailVerification();
              }
              sessionStorage.setItem('login','logged in')
              sessionStorage.setItem('user', firebaseUser.email)
              // Redirect to URL
              // @ts-ignore
              const redirectURL: string = window.redirectURL;
              // @ts-ignore
              let onlyBooklick: boolean = false;
              if (env['slug'] !== 'bibliomedic'){
                // @ts-ignore
                onlyBooklick = Resources[env['slug']].catalogue.length === 1 && Resources[env['slug']].catalogue[0].name ==='Booklick' ? true : false;
              }


              let currentLocation = window.location.href;


              if (currentLocation.includes(`${env['slug']}.booklick.co`) || currentLocation.includes(`app.bibliomedic.com`)) {
                sessionStorage.setItem('redirectURL','')
                const urlBooklick = currentLocation.split("=")[1]
                bkAccess(firebaseUser.email,`https://${urlBooklick.includes('bibliomedic') ? 'app.bibliomedic.com' : `${env['slug']}.booklick.co`}/v0.1/auth`)

                localStorage.setItem('redirectToBKUser', firebaseUser.email );
                localStorage.setItem('redirectToBKURL', `https://${urlBooklick.includes('bibliomedic') ? 'app.bibliomedic.com' : `${env['slug']}.booklick.co`}/v0.1/auth`);

              } else if (redirectURL) {
                sessionStorage.setItem('redirectURL',redirectURL)
                window.location.href = redirectURL;
              } else if (onlyBooklick){
                sessionStorage.setItem('redirectURL','')
                bkAccess(firebaseUser.email,`https://${env['slug'].includes('bibliomedic') ? 'app.bibliomedic.com' : `${env['slug']}.booklick.co`}/v0.1/auth`,'_self')

              } else if (!currentLocation.includes('/catalogo') && !currentLocation.includes('/tutorial')) {
                sessionStorage.setItem('redirectURL','')
                window.location.href = '/catalogo';
              } 
            }else{
              firebase.auth().signOut().then(() => {
                localStorage.clear();
                window.location.href = `/unauthorized`
              });
            }            
        }
        
      })

    ReactDOM.render(
      <React.StrictMode>
          <App />
      </React.StrictMode>,
      document.getElementById('root')
    );

    serviceWorker.unregister();
  })
