import config from '../general.json';


export const getInstitution = async (institution: string) => {
    try {
        let myHeaders = new Headers();
        myHeaders.append("env", institution);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders
        };

        let response = await fetch(config.msProxy + "/config", requestOptions)
        const configurarion = await response.json();

        return configurarion

    } catch (error) {
        console.log(error)
    }
};
