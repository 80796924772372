import React from "react";
import { Link } from "@reach/router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import './LogoutButton.css';

export interface LogoutButtonProps {}

export const LogoutButton: React.FC<LogoutButtonProps> = ({...props }) => {
  return (
    <Link to="/logout" className={'logout-button'} >
      Salir <FontAwesomeIcon className={'logout-icon'} icon={faSignOutAlt} />
    </Link>
  );
};
