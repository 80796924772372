import React from "react";
import './CardFunction.css';
import {AccessButton} from "../AccessButton";
import firebase from "firebase";

export interface CardFunctionProps {
  img: string,
  title: string,
  description: string,
  url: string,
  user: firebase.User|null
}

export const CardFunction: React.FC<CardFunctionProps> = ({ img, title, description, url, user, ...props }) => {
  return <div className={'function-card-container'}>
    <img alt='' className={'function-card-img'} src={img} />
    <h3 className={'function-card-title'}>{title}</h3>
    <p className={'function-card-description'}>{description}</p>
    <AccessButton
      url={url}
      isBooklick={title === 'Red social' || title === 'Encontrar Colegas'}
      isElibrolite={false}
      user={user? user.email: null}
    />
  </div>
}
