import React, {useState} from "react";
import './CreateUserAuth.css';
import {creatUser, signInUser} from "../../middleware/EmailAuth";
import firebase from "firebase";

export interface CreateUserAuthProps {
  redirectUrl?: string,
}

export const CreateUserAuth: React.FC<CreateUserAuthProps> = ({  redirectUrl, ...props}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [error, setError] = useState('');
  const [notification, setNotification] = useState('');

  // IS NEW Logic
  // @ts-ignore
  const institution: string = window.subdomin;
  let confirmPasswordDOM = undefined;
  let rememberPassword = undefined;

  if (isNew) {
    confirmPasswordDOM = <div className={'password-input-container'}>
      <input
        className={'password-input'}
        type="password"
        value={passwordConfirmation}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
        placeholder="Contraseña nuevamente"
      />
    </div>;
  }

  if (!isNew) {
    rememberPassword = <div className={'password-remember-container'}>
      <p
        className={'password-remember'}
        onClick={() => sendResetEmail(email, setError, setNotification)}
      >
        Recordar contraseña
      </p>
    </div>
  }
  const ctaText = isNew && institution !== 'juanncorpas' ? 'Crear usuario' : 'Ingresar';
  const textIsNew = isNew ? 'Iniciar sesión' : institution !== 'bibliomedic' ? 'Crear usuario' : '';

  // ERROR Logic

  let errorDOM = undefined;
  if (error !== '') {
    errorDOM = <div className={'information-container'}>
      <p className={'error'}>{error}</p>
    </div>
  }

  // NOTIFICATION Logic
  let notificationDOM = undefined;
  if (notification !== '') {
    notificationDOM = <div className={'information-container'}>
      <p className={'notification'}>{notification}</p>
    </div>
  }

  // Renderer

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      const valid = validateForm(
        isNew,
        email,
        password,
        passwordConfirmation,
        setError,
        setEmail,
        setPassword,
        setPasswordConfirmation
      );
      if (valid) {
        if (isNew) {
          creatUser(email, password, setError);
        } else {
          signInUser(email, password, setError);
        }
      }
    }} className={'create-user-form'}>
      {errorDOM}
      {notificationDOM}
      <div className={"email-input-container"}>
        {institution === 'ucp' && (
            <p className={'auth-method-message'}>Estudiantes</p>
        )}
        <input
          className={'email-input'}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo institucional"
        />
      </div>
      <div className={'password-input-container'}>
        <input
          className={'password-input'}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
        />
      </div>
      {rememberPassword}
      {confirmPasswordDOM}
      <div className={'options-container'}>
        <div className={'options'}>
          <input type="submit" value={ctaText}/>
         
        </div>
      </div>
    </form>
  );
};

const validateForm =
  (isNew: boolean,
   email: string,
   password: string,
   passwordConfirmation: string,
   setError: any,
   setEmail: any,
   setPassword: any,
   setPasswordConfirmation: any) => {


    // @ts-ignore
    const env = JSON.parse(sessionStorage.getItem('env'));

    setError('');
    if (!validateEmail(email)) {
      setEmail('');
      setError('El correo es inválido')
      return false;
    }
    if (!email.endsWith('booklick.co') && env['slug'] !== 'ucp' ? !email.endsWith(env['authorizedDomain']):false) {
      setEmail('');
      setError('Debe ser un correo institucional')
      return false;
    }
    if (isNew && password !== passwordConfirmation) {
      setPassword('');
      setPasswordConfirmation('');
      setError('Las contraseñas no coinciden');
      return false;
    }
    if (env['slug'] !== 'bibliomedic' ? password.length < 8 : false) {
      setPassword('');
      setPasswordConfirmation('');
      setError('Longitud mínima de la contraseña es de 8 caracteres');
      return false;
    }
    return true;

  }

const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const sendResetEmail = (email: string, setError: any, setNotification: any) => {
  if (!validateEmail(email)) {
    setError('Ingrese un correo válido para recuperar contraseña')
  } else {
    setError('');
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      setNotification('Si la cuenta existe, enviamos un correo electrónico con los pasos a seguir para recuperar la contraseña');
    }).catch((error) => {
      console.log(error);
      setNotification('Si la cuenta existe, enviamos un correo electrónico con los pasos a seguir para recuperar la contraseña');
    })
  }
}
