import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  googleLogin,
  googleLoginWithRedirect,
} from "../../middleware/GoogleAuth";
import { isMobile } from "react-device-detect";
import "./GoogleAuthButton.css";
import { useTranslation } from "react-i18next";

export interface GoogleAuthButtonProps {
  redirectUrl?: string;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  redirectUrl,
  ...props
}) => {
  const { t } = useTranslation();
  const isChrome = /Chrome/.test(navigator.userAgent);
  const env = JSON.parse(sessionStorage.getItem('env') ?? '');
  const slug = env["slug"];
  return (
    <button
      className={"google-auth-button"}
      onClick={() => {
        if (isChrome && slug === "juanncorpas" && !isMobile) {
          googleLoginWithRedirect(redirectUrl, t, isMobile);
        } else {
          googleLogin(redirectUrl, t,isMobile)
        }
      }}
    >
      <FontAwesomeIcon className={"gab-icon"} icon={faGoogle} /> Inicio de
      Sesión con Google
    </button>
  );
};
