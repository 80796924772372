import React, {useState} from "react";
import './PopLocation.css';
import {postCampusRegistration} from '../../middleware/Campus'


export interface PopLocationProps {
    user: firebase.User|null,
    campus: Array<string>,
    setModal: any      
}



export const PopLocation: React.FC<PopLocationProps> = ({user,campus,setModal,...props}) => {
    //@ts-ignore
    let institution: string = window.subdomin;
    const [selectcampus, setselectcampus] = useState("init");

    const toggle = () => setModal("submit")
    const submit = () => {
        //@ts-ignore
        postCampusRegistration(sessionStorage.getItem('user'),institution,selectcampus)
            .then(
                toggle()
            )
            .finally(toggle())  
        
    }

    const handleChange = (event:any) => {
        setselectcampus(event.target.value)
        
    }


     return (
        <div className={'containerPopLocation'}>
            <div className={'mainPop'}>
                <h3>¡Bienvenido!, ¿A qué sede de Uniguajira perteneces?</h3>
                
                <div className={'listOption'}>
                    {campus.map(item => {
                        return <input type='submit' className={`options`} value={item} onClick={handleChange} ></input>
                    })} 
                   
                </div>

                <button className={'continuar'} disabled={selectcampus === 'init' ? true: false}  onClick={submit}> Continuar </button>

            </div>
        </div>
    ) 
}
