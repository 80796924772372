import firebase from "firebase/app";
import 'firebase/auth';
import {getUser} from "./AccessBooklick";

export const microsoftLogin = (url: string | undefined, t: any) => {

  // @ts-ignore
  const env = JSON.parse(sessionStorage.getItem('env'));
  // @ts-ignore
  let provider = new firebase.auth.OAuthProvider('microsoft.com');
  firebase.auth().signInWithPopup(provider).then(function(result: any) {

    provider.setCustomParameters({
      tenant: 'a58800ba-af35-4eba-9264-3bce6223e5b0'
    });

    let credential = result.credential;
    let idToken = credential.idToken;

    const user = getUser(idToken);

    // @ts-ignor
    if (user.endsWith(env['authorizedDomain']) || user.endsWith('booklick.co')) {
      if (url) {
        window.location.href = url;
      }
    }
  }).catch(function(error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = error.credential;
    console.error(errorCode, errorMessage, email, credential);
  });
}
