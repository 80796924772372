import React from "react";
import './ResourcesContainer.css';
import {CatalogueHeader} from "../CatalogueHeader";
import Functions from '../../functions.json'
import {FuncSection} from "../FuncSection";
import firebase from "firebase";
import {LogoutButton} from "../LogoutButton";

export interface ResourcesContainerProps {
  institution: string,
  user: firebase.User|null
}

export const ResourcesContainer: React.FC<ResourcesContainerProps> = ({institution, user,...props }) => {

  // @ts-ignore
  const functionsList = Functions[institution];
  const sections = functionsList.sections.map((section: any) => {
    const lsIES= localStorage.getItem('ies');
    if (!lsIES || section.title !== 'Comunidad Bibliomedic') {
      return <FuncSection title={section.title} functionsList={section.functions} user={user}/>
    }
    return null;
  })

  return (
    <div className={'catalogue-container'}>
      <CatalogueHeader />
      {sections}
      <div className={'exit-button-container'}><LogoutButton /></div>
    </div>
  );
};
