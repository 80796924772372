import React from 'react';
import { Router, RouteComponentProps } from "@reach/router"
import { useTranslation } from 'react-i18next';
import Home from "./scenes/Home";
import Login from './scenes/Login';
import Logout from './scenes/Logout';
import Unauthorized from './scenes/Unauthorized';
import Catalogue from './scenes/Catalogue';
import AutomaticLogin from './scenes/AutomaticLogin';
import Tutorial from './scenes/Tutorial';
import './App.css';

const HomeRoute = (props: RouteComponentProps) => Home();
const LoginRoute = (props: RouteComponentProps) => Login();
const LogoutRoute = (props: RouteComponentProps) => Logout();
const UnauthorizedRoute = (props: RouteComponentProps) => Unauthorized();
const CatalogueRoute = (props: RouteComponentProps) => Catalogue();
const AutomaticLoginRoute = (props: RouteComponentProps) => AutomaticLogin();
const TutorialRoute = (props: RouteComponentProps) => Tutorial();

function App() {
  const { t } = useTranslation();
    return (
      <div className="App">
        <Router>
          <HomeRoute path={'/'} />
          <LoginRoute path={t('login-route')} />
          <LogoutRoute path={t('logout-route')} />
          <UnauthorizedRoute path={'unauthorized'} />
          <CatalogueRoute path={t('catalogue-route')} />
          <AutomaticLoginRoute path={'login-ref'} />
          <TutorialRoute path={'tutorial'} />
        </Router>
      </div>
    );
  }


export default App;
