import React from "react";
import './TutorialContainer.css';
import Resources from '../../tutorials.json';
import {CatalogueHeader} from '../CatalogueHeader';
import {TutorialCard} from '../TutorialCard';

export interface TutorialContainerProps {
  client: string
}

export const TutorialContainer: React.FC<TutorialContainerProps> = ({client, ...props }) => {

  // @ts-ignore
  const tutorials = Resources[client].videos;
  const tutorialCards = tutorials.map((item: any) => {
    return <TutorialCard title={item.title} url={item.url} />
  });

  return (
    <div className={'tutorial-container'}>
      <CatalogueHeader />
      <div className={'cards-container'} >
        { tutorialCards }
      </div>
    </div>
  );
};
