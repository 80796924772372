import React from 'react';
import {TutorialContainer} from "../components/TutorialContainer";

function Tutorial() {

  const lsIES= localStorage.getItem('ies');
  const client = lsIES || 'bibliomedic';

  return (
    <TutorialContainer client={client} />
  );
}

export default Tutorial;
