import $ from 'jquery';
import {getInstitution} from "./Instituion";

export const bkAccess = (user: string, url: string, target?:string) => {
  const form = $(`
    <form target="${target || '_blank'}" method="post" action="${url}">
      <input type="hidden" name="user" readonly value="${user}"> 
    </form>
  `);
  form.hide().appendTo('body');
  form.submit();
}

export const getUser = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  const response = JSON.parse(jsonPayload);
  return response.email;
};

export const elibroAccess = (user: string) => {
  console.log('elibroAccess entro')
  //@ts-ignore
  getInstitution(window.subdomin)
  .then(env=>{
      let Header = new Headers();
      //@ts-ignore
      Header.append("Authorization", `Token ${env.token}`);
      Header.append("Content-Type", "application/json");
      //@ts-ignore
      let body = JSON.stringify({secret:env.secret,channel_id:env.channel,user:user.email});
      let requestOptions = {
      method: 'POST',
      headers: Header,
      body: body,
      redirect: 'follow'
      };
      //@ts-ignore
      fetch("https://auth.elibro.net/auth/sso/", requestOptions)
        .then((response:any) => response.text())
        .then((result:any) => {
          window.location.href = (JSON.parse(result).url)
        })
        .catch((error:any) => console.log('error', error))
  })

}
