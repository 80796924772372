import React,{useState} from "react";
import './CatalogueContainer.css';
import {CatalogueHeader} from "../CatalogueHeader";
import Resources from '../../resources.json';
import {CardCatalogue} from "../CardCatalogue";
import { LogoutButton } from '../LogoutButton';
import firebase from "firebase";
import {PopLocation} from '../PopLocation'
import {getCampusRegistration} from '../../middleware/Campus'
import Loading from "react-loading";

export interface CatalogueContainerProps {
  user: firebase.User|null,
  institution: string
}

export const CatalogueContainer: React.FC<CatalogueContainerProps> = ({user, institution,...props }) => {


  const [modal, setModal] = useState("init");
  //@ts-ignore
  let resourcesListSort = Resources[institution].catalogue.sort((a,b)=> {
   if (a.index && b.index){
     if ( a.index < b.index){
       return -1;
     }

     if (a.index > b.index ){
       return 1;
     }
   }else {
     if ( a.name.toLowerCase() < b.name.toLowerCase()){
       return -1;
     }

     if (a.name.toLowerCase() > b.name.toLowerCase() ){
       return 1;
     }
   }
    return 0;

  })
  let onlyBooklick: boolean = false;
  if (institution !== 'bibliomedic'){
    // @ts-ignore
    onlyBooklick = Resources[institution].catalogue.length === 1 && Resources[institution].catalogue[0].name ==='Booklick' ? true : false;
  }
  if (modal === "init") {
    //@ts-ignore
    getCampusRegistration(sessionStorage.getItem('user'),institution)
    .then((res:any)=> {
      //@ts-ignore
      if(res.register ){
        setModal("register")
      }else{
        setModal("unregister")
      }
    })
  }


  let campus = ['Sede Villanueva', 'Sede Maicao', 'Sede Riohacha', 'Sede Fonseca']

  // @ts-ignore
  const resourcesList = resourcesListSort.map((item: any) => {
    const lsIES= localStorage.getItem('ies');
    if(lsIES){
      return null
    } else if (item['name'] !== 'Comunidad Médica' || item['name'] !== 'Video tutoriales') {
      return <CardCatalogue
      imgURL={item['img-logo']}
      name={item['name']}
      description={item['description']}
      url={item['url']}
      isBooklick={item['name'] === 'Booklick' || item['name'] === 'Comunidad Médica'}
      isElibrolite={item['type'] === 'eLibro lite'}
      user={user}
    />
    }
    return null
  })

  return (
    <div className={'catalogue-container'}>
      <CatalogueHeader />
      {
        institution === 'uniguajira' && modal === 'unregister' ? <PopLocation setModal={setModal} user={user} campus={campus}></PopLocation>:''
      }

      <div className={'catalogue-list'}>{onlyBooklick ?
        <div className={'input-group'}>
          <h1>Por favor espera un momento, te estamos redireccionando al contenido que necesitas</h1>
          <Loading type={'cylon'} color={'#F4436C'} height={100} width={80} />
        </div>
        :resourcesList}</div>
      <div className={'exit-button-container'}><LogoutButton /></div>
    </div>
  );
};
