import React from "react";
import './CardCatalogue.css';
import {AccessButton} from "../AccessButton";
import firebase from "firebase";

export interface CardCatalogueProps {
  imgURL: string,
  name: string,
  description: string,
  url: string
  isBooklick: boolean,
  isElibrolite: boolean,
  user: firebase.User|null
}

export const CardCatalogue: React.FC<CardCatalogueProps> =
  ({imgURL,name,description, url, isBooklick,isElibrolite,user, ...props }) => {
  return (
    <div className={'card-container'}>
      <img className={'catalogue-card-img'} src={imgURL} alt={name} />
      <h3 className={'catalogue-card-title'}>{name}</h3>
      <p className={'catalogue-card-description'}>{description}</p>
      <AccessButton url={url} isBooklick={isBooklick} isElibrolite={isElibrolite} user={localStorage.lmsUser ? localStorage.lmsUser : !user ? null : user.email} />
    </div>
  );
};
