import React, {useState} from 'react';
import {CatalogueContainer} from "../components/CatalogueContainer";
import { useNavigate } from "@reach/router"

import firebase from "firebase";
import Resources from "../resources.json";
import {ResourcesContainer} from "../components/ResourcesContainer";

function Catalogue() {
  const [user, setUser]:any = useState(undefined);

  // VALIDATION
  const navigate = useNavigate()

  // Validation
  firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (!firebaseUser) {
      navigate('login', {replace: true});
    } else {
      setUser(firebaseUser);
    }
  })

  const lsIES= localStorage.getItem('ies');
  let contentContainer = null;
  
  if (lsIES !== 'cmp') {

    // Functions or resources
    // @ts-ignore
    let institution: string = window.subdomin;
    //@ts-ignore
    let isResources = !!Resources[institution];

    if (isResources) {
      contentContainer = <CatalogueContainer user={user} institution={institution} />;
    } else {
      contentContainer = <ResourcesContainer user={user} institution={institution} />
    }
  } else {
    contentContainer = <div style={{margin: '10%'}}>
      <h1>Estimado usuario de Bibliomedic,</h1>
      <h1>Tu membresía auspiciada por el Colegio Médico del Perú ha caducado. Te invitamos a contactarte con tu administrador para solicitar un nuevo acceso</h1>
      <h2>sistemas@cmp.org.pe</h2>
    </div>
  }

  return contentContainer;
}

export default Catalogue;
